import React from "react";
import { useCarrito } from "../../../hook/UseCarrito";

import './pago.css'

export default function Pago() {
  const { carrito, getTotalITems } = useCarrito();

  const totalPagar = carrito.reduce(
    (total, item) => total + item.producto.precio * item.quantity,
    0
  );

  const confirmarPago = () => {
    alert(`!Gracias por tu compra!, el total es $${totalPagar}`);
  };

  return (
    <div className="productos-shop">
      <h2>Resumen de tu compra</h2>
      <ul>
        {carrito.map((item) => (
          <div key={item.producto.id} className="box-shop">
            <img src={item.producto.imagen} alt={item.producto.nombre} />
            <h5>{item.producto.nombre}</h5>
            <p>{item.producto.descripcion}</p>
            <h3> US$ {item.producto.precio}</h3>
          </div>
        ))}
      </ul>
      <h3>Total a pagar: ${totalPagar}</h3>
      <button onClick={confirmarPago} className="proceder">Confirmar pago</button>
    </div>
  );
}
