import React, { useEffect, useState } from "react";
import "./carrito.css";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Catalogo from "./catalogo/Catalogo";
import { useCarrito } from "../../hook/UseCarrito";
import Megusta from "./meGusta/Megusta";
import Shop from "./shop/Shop";
import Pago from "./pago/Pago";

export default function Carrito() {
  const { getTotalItems, getTotalLikes, meGusta } = useCarrito();

  //Estado para manejar el componente que se debe mostrar
  const [vista, setVista] = useState("catalogo"); //Por defecto mostramos el catalogo

  const mostrarFavoritos = () => {
    setVista("megusta");
  };

  const mostrarCatalogo = () => {
    setVista("catalogo");
  };

  const mostrarShop = () => {
    setVista("Shop");
  };

  const mostrarPago = () => {
    setVista("pago");
  };

  const renderVista = () => {
    switch (vista) {
      case "catalogo":
        return <Catalogo />;
      case "megusta":
        return <Megusta />;
      case "Shop":
        return <Shop onPagar={mostrarPago} />;
      case "pago":
        return <Pago />;
      default:
        return <Catalogo />;
    }
  };

  return (
    <div className="carrito">
      <div className="info">
        <h3 onClick={mostrarCatalogo}>E-Comerce</h3>
        <span
          className={`cartIcon heart ${
            getTotalLikes() > 0 ? "heartActivo" : ""
          }`}
        >
          <FontAwesomeIcon icon={faHeart} onClick={mostrarFavoritos} />
        </span>
        <span className="cartIcon">
          <span className="totalItems">{getTotalItems()}</span>
          <FontAwesomeIcon icon={faCartShopping} onClick={mostrarShop} />
        </span>
      </div>

      <input type="text" className="search" />
      <ul className="segmentos">
        <li>Explorar</li>
        <li>Electronica</li>
        <li>Ropa</li>
        <li>Mas Buscado</li>
      </ul>
      {/*RENDERIZA EL COMPONENTE BASADO EN EL ESTADO 'VISTA'*/}
      {renderVista()}
    </div>
  );
}
