import React from "react";
import { useCarrito } from "../../../hook/UseCarrito";

import "./shop.css";

export default function Shop({ onPagar }) {
  const { carrito, removeFromCarrito } = useCarrito();

  return (
    <div className="productos-shop">
      <h2>Tu Carrito</h2>
      {carrito.length === 0 ? (
        <p>Carrito Vacio</p>
      ) : (
        carrito.map(({ producto }) => (
          <div key={producto.id} className="box-shop">
            <img src={producto.imagen} alt={producto.nombre} />
            <h5>{producto.nombre}</h5>
            <p>{producto.descripcion}</p>
            <h3> US$ {producto.precio}</h3>
            <button
              className="delete"
              onClick={() => removeFromCarrito(producto.id)}
            >
              Eliminar del carrito
            </button>
            <button onClick={onPagar}>Proceeder Pago</button>
          </div>
        ))
      )}
    </div>
  );
}
