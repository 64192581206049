import React, { useEffect, useState } from "react";
import "./renders.css";
import HeaderSmall from "../components/headerSmall/HeaderSmall";
import { rutaAPI } from "../config";

export default function Renders() {
  //1 - Configurar los hooks
  const [galeria, setGaleria] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //2 - Funcion para mostrar los datos con tetch
  const verFoto = async () => {
    setIsLoading(true);
    try {
      const response = await getData();
      const data = await response.json();

      if (!data || !Array.isArray(data.docs)) {
        throw new Error(
          "La respuesta de la api no contiene los datos esperados"
        );
      }
      // Asignar la primera imagen de la galería (o cualquiera que desees).
      if (data.docs.length > 0) {
        const imagenes = data.docs.map(
          (doc) => `${rutaAPI}/api/verPic/${doc.id}`
        );
        setGaleria(imagenes);
        console.log(data.docs);
      }
    } catch (error) {
      console.error("error la obtener los datos", error);
    } finally {
      setIsLoading(false);
    }
  };

  const agrandar = (foto) => {
    setSelectedImage(foto);
    console.log(selectedImage);
  };

  const cerrarModal = (foto) => {
    setSelectedImage(null);
    console.log(selectedImage);
  };
  useEffect(() => {
    verFoto();
  }, []);

  return (
    <div className="renders">
      <HeaderSmall />
      <div className="encabezado">
        <a className="seleccion" href="/diseño">
          <h3>Mis Diseños</h3>
        </a>
        <a>
          <h2>Mis Renders</h2>
        </a>
        <a className="seleccion" href="/#">
          <h3>Mis Codigos</h3>
        </a>
      </div>
      {/*MOSTRAR ICONO DE CARGA SI ESTA CARGANDO*/}
      {isLoading ? (
        <div className="loading">
          <span>Cargando imagenes...</span>
        </div>
      ) : (
        <div className="gallery" onContextMenu={(e) => e.preventDefault()}>
          {galeria.map((foto, index) => (
            <div
              key={index}
              onClick={() => agrandar(foto)}
              onContextMenu={(e) => e.preventDefault()}
            >
              <img
                src={foto}
                alt={`photo-${index}`}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          ))}
        </div>
      )}
      {selectedImage && (
        <div className="modal" onClick={cerrarModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="imagen ampliada" />
          </div>
        </div>
      )}
    </div>
  );
}

/*PETICION GET PARA GALERIA*/
const getData = async () => {
  try {
    const url = `${rutaAPI}/api/mostrarPic`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return response;
  } catch (err) {
    return err;
  }
};
